

$(function() {

    checkScroll();

    var lines = 18;
    var textLength = 39;

    for (i = 0; i < lines - 1; i++) {
        $(".num").first().clone().insertAfter($(".num").first());
    }

    var active = true;
    var interval;

    $(".bin-wrap").hover(
        function() {
            if (active) {

                interval = setInterval(function() {
                    $(".num").each(function() {
                        if ($(this).text().length < textLength) {
                            $(this).text(($(this).text() + Math.round(Math.random())));
                        } else {
                            $(this).text(Math.round(Math.random()) + $(this).text().substring(0, $(this).text().length - 1))
                        }
                    });
                }, 75);

                active = false;
            }
        }, function() {
            
            clearInterval(interval); 
            active = true;
        }
    );

    $(".bin-wrap").on('mouseover', function() {
        if (active) {

            var interval = setInterval(function() {
                $(".num").each(function() {
                    if ($(this).text().length < textLength) {
                        $(this).text(($(this).text() + Math.round(Math.random())));
                    } else {
                        $(this).text(Math.round(Math.random()) + $(this).text().substring(0, $(this).text().length - 1))
                    }
                });
            }, 75);

            active = false;

            setTimeout(function() { 
                clearInterval(interval); 
                active = true;
            }, 3000);
        }
        
        
    });
     

    $("#owl-fg .item").hover(
        function() {
            $('.item-info').show();
            $('.item-main-img').addClass('blurred');
        }, function() {
            $('.item-info').hide();
            $('.item-main-img').removeClass('blurred');
        }
    );



    var owl_bg = $("#owl-bg");
    var owl_fg = $("#owl-fg");
     
    owl_bg.owlCarousel({
        center: true,
        items: 12,
        loop:true,
        dots: false,
        mouseDrag: true,
        // autoplay:true,
        // autoplayTimeout:2000,
        // autoplayHoverPause:true      
    });

    owl_fg.owlCarousel({
        center: true,
        items: 1,
        loop:true,
        dots: false,  
        mouseDrag: false,
        // autoplay:true,
        // autoplayTimeout:2000,
        // autoplayHoverPause:true         
    });            

    owl_bg.on('dragged.owl.carousel', function (e) {
            if (e.relatedTarget.state.direction == 'left') {
                owl_fg.trigger('next.owl.carousel')
            } else {
                owl_fg.trigger('prev.owl.carousel')
            }
    });

    owl_fg.on('dragged.owl.carousel', function (e) {
            if (e.relatedTarget.state.direction == 'left') {
                owl_bg.trigger('next.owl.carousel')
            } else {
                owl_bg.trigger('prev.owl.carousel')
            }
    });


    $(".next-item").click(function(){
        owl_bg.trigger('next.owl.carousel');
        owl_fg.trigger('next.owl.carousel');
    })
    
    $(".prev-item").click(function(){
        owl_bg.trigger('prev.owl.carousel');
        owl_fg.trigger('prev.owl.carousel');
    })
    

    var hash = window.location.hash;
    hash = hash.substring(1);

    // if (hash == 'projecten' || hash == 'technieken' || hash == "over-ons" || hash == "contact") {
    //     moveToTarget(hash);                
    //     return false;
    // }

    $(window).on('scroll', function() {
        
        checkScroll();

    });

    $('.down_from_home').click(function() {
        window.location.hash = "over-ons";
        moveToTarget('over-ons');                
        return false;
    });

    $('.nav a').click(function() {
        window.location.hash = $(this).data('target');
        moveToTarget($(this).data('target'));                
        return false;
    });

    $('.send_telephone').click(function() {
        var tel = $('#t_tel');
        var token = $('#token').val();

        if (tel.val()) {
            tel.removeClass('error');

            $('.tel_question').hide();
            $('.tel_answer').show();
            
            $.post("/send-number", {"_token": token, "telephone": tel.val()}, function(r) {
                console.log(r);
            });
        }
        else {
            tel.addClass('error');
        }
    });
}); 

function checkScroll() {
    var menu = $('.navbar-default');
    var break_point = $("div[data-destination='over-ons']").offset().top - menu.outerHeight();
    var original = 0;

    if ($(window).scrollTop() >= break_point) {

        menu.addClass("navbar-fixed-top");
        $('body').addClass("padding-top-64");
    }

    else {
        menu.removeClass("navbar-fixed-top");
        $('body').removeClass("padding-top-64");
    }
}

function moveToTarget(target) {
    var offsettop = 0;            
    
    if (target != "home") {
        $('html, body').animate({
            scrollTop: $("div[data-destination='" + target +"']").offset().top - offsettop
        }, 600); 
    }
    else {
        $('html, body').animate({
            scrollTop: 0
        }, 600); 
    }
}

